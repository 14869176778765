/**
 * Contains all parameters and submodels for display in the Advanced section tab.
 * To add new parameters, just add one to the Parameters list, and make sure the submodel exists in the submodel array below. 
 */
var AdvancedParameterList = {
    Submodel: [
        "Glucose",
        "Insulin",
        "Glucagon",
        "Glucose absorption",
        "Glucose metabolic rates",
        "Pancreas",
        "Incretins",
        "Metformin",
        "Long-acting insulin",
        "Fast-acting insulin",
        "Physical activity",
        "Stress"
    ],
    Parameters: {
        VGBC: {name:"VGBC", submodel: "Glucose", Unit: "dL" },
        VGBF: {name: "VGBF", submodel: "Glucose", Unit: "dL" },
        VGH: {name: "VGH", submodel: "Glucose", Unit: "dL" },
        VGL: {name: "VGL", submodel: "Glucose", Unit: "dL" },
        VGG: {name: "VGG", submodel: "Glucose", Unit: "dL" },
        VGK: {name: "VGK", submodel: "Glucose", Unit: "dL" },
        VGPC: {name: "VGPC", submodel: "Glucose", Unit: "dL" },
        VGPF: {name: "VGPF", submodel: "Glucose", Unit: "dL" },
        QGB: {name: "QGB", submodel: "Glucose", Unit: "dL min⁻¹" },
        QGH: {name: "QGH", submodel: "Glucose", Unit: "dL min⁻¹" },
        QGA: {name: "QGA", submodel: "Glucose", Unit: "dL min⁻¹" },
        QGL: {name: "QGL", submodel: "Glucose", Unit: "dL min⁻¹" },
        QGG: {name: "QGG", submodel: "Glucose", Unit: "dL min⁻¹" },
        QGK: {name: "QGK", submodel: "Glucose", Unit: "dL min⁻¹" },
        QGP: {name: "QGP", submodel: "Glucose", Unit: "dL min⁻¹" },
        TGB: {name: "TGB", submodel: "Glucose", Unit: "min" },
        TGP: {name: "TGP", submodel: "Glucose", Unit: "min" },
        VIB: {name: "VIB", submodel: "Insulin", Unit: "L" },
        VIH: {name: "VIH", submodel: "Insulin", Unit: "L" },
        VIG: {name: "VIG", submodel: "Insulin", Unit: "L" },
        VIL: {name: "VIL", submodel: "Insulin", Unit: "L" },
        VIK: {name: "VIK", submodel: "Insulin", Unit: "L" },
        VIPF: {name: "VIPF", submodel: "Insulin", Unit: "L" },
        QIB: {name: "QIB", submodel: "Insulin", Unit: "L min⁻¹" },
        QIH: {name: "QIH", submodel: "Insulin", Unit: "L min⁻¹" },
        QIA: {name: "QIA", submodel: "Insulin", Unit: "L min⁻¹" },
        QIK: {name: "QIK", submodel: "Insulin", Unit: "L min⁻¹" },
        QIP: {name: "QIP", submodel: "Insulin", Unit: "L min⁻¹" },
        QIG: {name: "QIG", submodel: "Insulin", Unit: "L min⁻¹" },
        TIP: {name: "TIP", submodel: "Insulin", Unit: "min" },
        ml0: {name: "ml0", submodel: "Insulin", Unit: "U" },
        QIL: {name: "QIL", submodel: "Insulin", Unit: "L min⁻¹" },
        VIPC: {name: "VIPC", submodel: "Insulin", Unit: "L" },
        VGamma: {name: "VGamma", submodel: "Glucagon", Unit: "mL" },
        fg: {name: "fg", submodel: "Glucose absorption", Unit: "." },
        Kq1: {name: "Kq1", submodel: "Glucose absorption", Unit: "." },
        Kq2: {name: "Kq2", submodel: "Glucose absorption", Unit: "." },
        k12: {name: "k12", submodel: "Glucose absorption", Unit: "min⁻¹" },
        k12GIH: {name: "k12GIH*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        k12GIM: {name: "k12GIM*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        k12GIL: {name: "k12GIL*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        k12GIvL: {name: "k12GIvL*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        kmin: {name: "kmin", submodel: "Glucose absorption", Unit: "min⁻¹" },
        kmax: {name: "kmax", submodel: "Glucose absorption", Unit: "min⁻¹" },
        kabs: {name: "kabs", submodel: "Glucose absorption", Unit: "min⁻¹" },
        kabsGIH: {name: "kabsGIH*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        kabsGIM: {name: "kabsGIM*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        kabsGIL: {name: "kabsGIL*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        kabsGIvL: {name: "kabsGIvL*", submodel: "Glucose absorption", Unit: "min⁻¹" },
        c1: {name: "c1", submodel: "Glucose metabolic rates", Unit: "." },
        c2: {name: "c2", submodel: "Glucose metabolic rates", Unit: "." },
        c3: {name: "c3", submodel: "Glucose metabolic rates", Unit: "." },
        c4: {name: "c4", submodel: "Glucose metabolic rates", Unit: "." },
        c5: {name: "c5", submodel: "Glucose metabolic rates", Unit: "." },
        d1: {name: "d1", submodel: "Glucose metabolic rates", Unit: "." },
        d2: {name: "d2", submodel: "Glucose metabolic rates", Unit: "." },
        d3: {name: "d3", submodel: "Glucose metabolic rates", Unit: "." },
        d4: {name: "d4", submodel: "Glucose metabolic rates", Unit: "." },
        d5: {name: "d5", submodel: "Glucose metabolic rates", Unit: "." },
        SPGU: {name: "SPGU*", submodel: "Glucose metabolic rates", Unit: "." },
        SHGP: {name: "SHGP*", submodel: "Glucose metabolic rates", Unit: "." },
        zeta1: {name: "zeta1", submodel: "Pancreas", Unit: "L pmol⁻¹" },
        zeta2: {name: "zeta2", submodel: "Pancreas", Unit: "L (pmol min)⁻¹" },
        ml0: {name: "ml0", submodel: "Pancreas", Unit: "U" },
        Kl: {name: "Kl", submodel: "Pancreas", Unit: "min⁻¹" },
        Ks: {name: "Ks", submodel: "Pancreas", Unit: "min⁻¹" },
        gammapan: {name: "gammapan", submodel: "Pancreas", Unit: "μg min⁻¹" },
        alphapan: {name: "alphapan", submodel: "Pancreas", Unit: "min⁻¹" },
        betapan: {name: "betapan", submodel: "Pancreas", Unit: "min⁻¹" },
        N1: {name: "N1", submodel: "Pancreas", Unit: "min⁻¹" },
        N2: {name: "N2", submodel: "Pancreas", Unit: "min⁻¹" },
        VPSI: {name: "VPSI", submodel: "Incretins", Unit: "dL" },
        Kout: {name: "Kout", submodel: "Incretins", Unit: "min⁻¹" },
        CF2: {name: "CF2", submodel: "Incretins", Unit: "min⁻¹ nmol⁻¹" },
        tpsi: {name: "tpsi", submodel: "Incretins", Unit: "min⁻¹" },
        zeta: {name: "zeta", submodel: "Incretins", Unit: "." },
        zetae: {name: "zetae", submodel: "Incretins", Unit: "." },
        ah2:  {name: "ah2", submodel: "Incretins", Unit: "." }, //Daily GLP-1
        bh2:  {name: "bh2", submodel: "Incretins", Unit: "." },
        ch2:  {name: "ch2", submodel: "Incretins", Unit: "." },
        ah24:  {name: "ah24", submodel: "Incretins", Unit: "." },//Weekly GLP-1
        bh24:  {name: "bh24", submodel: "Incretins", Unit: "." },
        ch24:  {name: "ch24", submodel: "Incretins", Unit: "." },
        RmaxC: {name: "RmaxC", submodel: "Incretins", Unit: "nmol" },
        Fv: {name: "Fv", submodel: "Vildagliptin", Unit: "." },
        ka1: {name: "ka1", submodel: "Vildagliptin", Unit: "min⁻¹" },
        ka2: {name: "ka2", submodel: "Vildagliptin", Unit: "min⁻¹" },
        CL: {name: "CL", submodel: "Vildagliptin", Unit: "L min⁻¹" },
        CLic: {name: "CLic", submodel: "Vildagliptin", Unit: "L min⁻¹" },
        Vp: {name: "Vp", submodel: "Vildagliptin", Unit: "L" },
        Vc: {name: "Vc", submodel: "Vildagliptin", Unit: "L" },
        kvd: {name: "kvd", submodel: "Vildagliptin", Unit: "nmol L⁻¹" },
        k2v: {name: "k2v", submodel: "Vildagliptin", Unit: "min⁻¹" },
        koff: {name: "koff", submodel: "Vildagliptin", Unit: "min⁻¹" },
        RmaxP: {name: "RmaxP", submodel: "Vildagliptin", Unit: "nmol" },
        kdeg: {name: "kdeg", submodel: "Vildagliptin", Unit: "min⁻¹" },
        kgo: {name: "kgo", submodel: "Metformin", Unit: "min⁻¹" },
        kgg: {name: "kgg", submodel: "Metformin", Unit: "min⁻¹" },
        kpg: {name: "kpg", submodel: "Metformin", Unit: "min⁻¹" },
        kgl: {name: "kgl", submodel: "Metformin", Unit: "min⁻¹" },
        kpl: {name: "kpl", submodel: "Metformin", Unit: "min⁻¹" },
        klp: {name: "klp", submodel: "Metformin", Unit: "min⁻¹" },
        kpo: {name: "kpo", submodel: "Metformin", Unit: "min⁻¹" },
        vGWmax: {name: "vGWmax", submodel: "Metformin", Unit: "." },
        vLmax: {name: "vLmax", submodel: "Metformin", Unit: "." },
        vPmax: {name: "vPmax", submodel: "Metformin", Unit: "." },
        nGW: {name: "nGW", submodel: "Metformin", Unit: "." },
        nL: {name: "nL", submodel: "Metformin", Unit: "." },
        nP: {name: "nP", submodel: "Metformin", Unit: "." },
        phiGW50: {name: "phiGW50", submodel: "Metformin", Unit: "." },
        phiL50: {name: "phiL50", submodel: "Metformin", Unit: "." },
        phiP50: {name: "phiP50", submodel: "Metformin", Unit: "." },
        rhoalpha: {name: "rhoalpha", submodel: "Metformin", Unit: "min⁻¹" },
        rhobeta: {name: "rhobeta", submodel: "Metformin", Unit: "min⁻¹" },
        alpham: {name: "alpham", submodel: "Metformin", Unit: "min⁻¹" },
        betam: {name: "betam", submodel: "Metformin", Unit: "min⁻¹" },
        pla: {name: "pla", submodel: "Long-acting insulin", Unit: "min⁻¹" },
        rla: {name: "rla", submodel: "Long-acting insulin", Unit: "." },
        qla: {name: "qla", submodel: "Long-acting insulin", Unit: "dL²mU⁻²" },
        bla: {name: "bla", submodel: "Long-acting insulin", Unit: "min⁻¹" },
        Cmax: {name: "Cmax", submodel: "Long-acting insulin", Unit: "." },
        kla: {name: "kla", submodel: "Long-acting insulin", Unit: "min⁻¹" },
        kcll: {name: "kcll", submodel: "Long-acting insulin", Unit: "mU min⁻¹" },
        sla: {name: "sla", submodel: "Long-acting insulin", Unit: "." },
        pfa: {name: "pfa", submodel: "Fast-acting insulin", Unit: "min⁻¹" },
        rfa: {name: "rfa", submodel: "Fast-acting insulin", Unit: "." },
        sfa: {name: "sfa", submodel: "Fast-acting insulin", Unit: "." },
        qfa: {name: "qfa", submodel: "Fast-acting insulin", Unit: "dL²mU⁻²" },
        kclf: {name: "kclf", submodel: "Fast-acting insulin", Unit: "mU min⁻¹" },
        bfa: {name: "bfa", submodel: "Fast-acting insulin", Unit: "min⁻¹" },
        bfa: {name: "bfa", submodel: "Lispro", Unit: "min⁻¹" },
        tHR: {name: "tHR", submodel: "Physical activity", Unit: "min" },
        ne: {name: "ne", submodel: "Physical activity", Unit: "." },
        ae: {name: "ae", submodel: "Physical activity", Unit: "." },
        te: {name: "te", submodel: "Physical activity", Unit: "min" },
        alphae: {name: "alphae", submodel: "Physical activity", Unit: "." },
        betae: {name: "betae", submodel: "Physical activity", Unit: "bpm⁻¹" },
        HRb: {name: "HRb", submodel: "Physical activity", Unit: "bpm" },
        fStress: {name: "fStress", submodel: "Stress", Unit: "." },
        tauMIHGP: {name: "tauMIHGP", submodel: "Glucose metabolic rates", Unit: "." },
        taufr: {name: "taufr", submodel: "Glucose metabolic rates", Unit: "." },
        tauMIHGU: {name: "tauMIHGU", submodel: "Glucose metabolic rates", Unit: "." },
    }
}
export default AdvancedParameterList