<template>
<div class="Param">
    <div  @click="handleShowParam" :class="{'header': true, 'in-use' : inUse}" :data-v-step="paramType">
        <p>{{paramType}}</p>
        <i :class='{ "rotate": showParam && showAll}'><font-awesome-icon class="icon" icon="chevron-down"/></i>
    </div>
    <Collapse>
       <!-- Expands parameter dropdown if showParam is true -->
        <div ref="wrapper" class="param-content" v-show="showParam && showAll">
            <!-- Check if Insulin selection should be displayed -->
            <div class="insulin" v-if="Insulin">
                <label>Select insulin type:</label>
                <select @change="updateInsulin" v-model="SelectedInsulin">
                    <option :key="insulin" v-for="insulin in InsulinList">{{insulin}}</option>
                </select>
            </div>
            <div class="repeat">
                <label for="repeat">Repeat:</label>
                <select @change="updateRepeat" v-model="parRepeat" name="repeat">
                    <option value="none">none</option>
                    <option value="daily">daily</option>
                    <option value="weekly">weekly</option>
                </select>
            </div>
            <!-- Four option = start and end time -->
            <!-- Three option = single time -->
            <!-- Checks if four param should be displayed -->
            <FourOptionList v-if="fourOption"
                @add-param="$emit('add-param', {type:Index})"
                @delete-param="deleteParam"
                @updateValue="updateValue"
                :param="param"
                :option="paramOption"
                :repeat="parRepeat"
                :paramType="paramType"/>
            <!-- Else three param should be displayed -->            
            <ThreeOptionList v-else
                @add-param="$emit('add-param', {type:Index})"
                @delete-param="deleteParam"
                @updateValue="updateValue"
                :param="param"
                :option="paramOption"
                :repeat="parRepeat"
                :paramType="paramType"
                :Types="Types"
                :predefinedDose="predefinedDose"
                :typeName="typeName"/>
        </div>
    </Collapse>
</div>

</template>
<script>
import FourOptionList from "./FourOption-List.vue";
import ThreeOptionList from "./ThreeOption-List.vue";
import Collapse from './Transitions/Collapse.vue'
/**
 * The component used as a container for a section (External factors / Treatment)
 */
export default{
    name: "Parameter",
    props:{
         /**
         * A boolean specifying if all parameter dropdowns should be expanded
         */
        showAll: Boolean,
        /**
         * A boolean specifying if the parameter is in use
         */
        inUse : Boolean,
        /**
         * An array of parameters
         */
        param: Array,
        /**
         * Does the parameter need two time instanced (Duration)
         */
        fourOption: {
            type: Boolean,
            default: false
        },
        /**
         * Name of the parameter
         */
        paramType: String,
        /**
         * Unit of the parameter value (ex. Carbohydrates [g]).
         */
        paramOption: String,
        /**
         * If the event is repeated. Used to determin if days should be displayed.
         * @values none, daily, weekly 
         */
        parRepeat: {
            type: String,
            default: 'none'
        },
        /**
         * Is it a type of insulin (Used to determin if the insulin selection should be displayed).
         */
        Insulin: {
            type: Boolean,
            default: false,
        },
        /**
         * Array containing the different kinds of insulin that can be selected.
         */
        InsulinList: Array,
        /**
         * String containing the currently selected insulin type.
         */
        SelectedInsulin: String,
        /**
         * An array containing the different kinds of types a parameter can have (the dropdown menu for meals)
         */
        Types: Array,
        /**
         * Name of the type (ex. Glycimic index).
         */
        typeName: String,
        /**
         * Array of predefined dose sizes
         */
        predefinedDose: Array,
        /**
         * What index is the parameter, generated by the for-loop in the parent
         */
        Index: String,
    },
    emits: ['add-param', 'deleteParam', 'updateValue', 'updateRepeat','updateInsulin','showAll'],
    methods: {
      
        handleShowParam() {
          this.showParam = !this.showParam;
        },
        // Emits the event of child component
        updateValue(par){
            /**
             * Emits the updateValue from child component
             * @param {String} type The type of parameter
             * @param {String} id Id of the parameter from child
             * @param {String} valType The type of value that was changed
             * @param {Number} val The new value
             */
            this.$emit('updateValue', {type: this.Index, id:par.id, valType:par.valueType, val:par.value})
        },
        // Emits the event of child component
        deleteParam(id){
            /**
             * Emits the deleteParam from child component
             * @param {String} id Id of the parameter
             * @param {String} type The type of parameter
             */
            this.$emit('deleteParam', {id:id, type: this.Index})
        },
        // Emits the event of child component
        updateRepeat(event){
            /**
             * Emits the update of repeat
             * @param {String} index Index of the parameter
             * @param {Number} val New value for the repeat
             */
            this.$emit('updateRepeat', {index:this.Index, val:event.srcElement.value})
        },
        // Emits change in insulin type
        updateInsulin(event){
            var type = '';
            type = this.paramType.split(' ');
            /**
             * Emits the update of insulin
             * @param {String} type The selected insulin type
             * @param {Number} val New value for the repeat
             */
            this.$emit('updateInsulin', {type:type[0],val:event.srcElement.value});
        }
    },
    data() {
        return {
            showParam: false,
        };
    },
    components: {
        FourOptionList,
        ThreeOptionList,
        Collapse
    },
    watch: { 
        showAll: function(newVal, oldVal) { // watch it
          if(newVal == false) {
            this.showParam = false;
          }
        }
      }

}
</script>

<style scoped>
.Param {
    position: relative;
    z-index: 2;
    padding: 2px 10px;
}
.Param i{
    color: black;
    display:inline-block;
    font-size: 20px;
    line-height: 30px;    
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: bottom;
    cursor: pointer;
}
.Param .header{
    position: relative;
    z-index: 0;
    display: grid;
    grid-template-columns: calc(100% - 30px) 30px;
}
.header p{
    text-align: left;
    font-size: 1em;
    line-height: 30px;
    font-weight: 500;
    margin: 0;
    cursor: pointer;
}

.Param:hover{
  background-color: rgb(241, 241, 241);
}
.header i{
    font-size: 20px;
    
    transition: all .8s ease;
    transform: rotate(0deg);
}
.header .rotate{
    transform: rotate(180deg);
}
.param-content{
    position: relative;
    z-index: 10;
}

.in-use p {
  font-weight: 600;
}
.in-use p:before {
  content: "• ";

}
.insulin{
    position: relative;
    z-index: 0;
}
.repeat{
    position: relative;
    z-index: 0;
    text-align: left;
    padding: 5px 0;
}
label{
    font-size: .8em;
    padding-right: 10px;
}
</style>