<template>
    <ParameterHeader class="summary-header" text="Summary"/>
    <div :class="{'overlay':newSimRequired}">
        <div id="summary-select">
        <label class="small-text">Show: </label>
        <select v-model="selectedSummary">
            <option>None</option>
            <option :key="item.name" v-for="item in Response" :selected="item.name == selectedSummary">
                {{ item.name }}
            </option>
        </select>
    </div>
    <table>
        <thead >
            <th colspan="4">External</th>
        </thead>
        <tr>
        <th></th>
        <th>Time</th>
        <th>Value</th>
        <th>Repeat</th>
        </tr>
        <template v-for="(par,key,index) in savedParameters.External?.[selectedSummary]?.data">
            <!-- Gets the name of the external factor -->
            <tr v-if="savedParameters.External?.[selectedSummary]?.data[external[index]].length > 0">
                <th :rowspan="par.length + 1">{{ external[index] }}</th>
            </tr>
            <!-- Cycles the parameters of the given kind -->
            <tr v-for="p in par" v-if="simPar.parVector[external[index]].data.length > 0">
                <td>{{ p.startTime >= 0 ? `${convertMinutesToTime(p.startTime)} - ${convertMinutesToTime(p.endTime)}` : convertMinutesToTime(p.time)}}</td>
                <td>{{`${p.value} ${p.type}`}}</td>
                <td>{{Object.values(simPar.repeat)[index]}}</td>
            </tr>
        </template>
        <thead>
        <th colspan="4">Treatment</th>
        </thead>
        <tr>
        <th></th>
        <th>Time</th>
        <th>Value</th>
        <th>Repeat</th>
        </tr>
        <template v-for="(par,key,index) in savedParameters.Treatment?.[selectedSummary]?.data">
            <!-- Gets the name of the treatment -->
            <tr v-if="savedParameters.Treatment?.[selectedSummary]?.data[treatment[index]].length > 0">
                <th :rowspan="par.length + 1">{{ treatment[index] }}</th>
            </tr>
            <!-- Cycles the doses of the given kind -->
            <tr v-for="p in par"  v-if="simPar.parVector[treatment[index]].data.length > 0">
                <td>{{ p.startTime >= 0 ? `${convertMinutesToTime(p.startTime)} ${convertMinutesToTime(p.endTime)}` : convertMinutesToTime(p.time)}}</td>
                <td>{{`${p.value} ${p.type}`}}</td>
                <td>{{Object.values(simPar.repeat)[index]}}</td>
            </tr>
        </template> 
    </table>
    <p class="sim-again-text" v-if="newSimRequired">Summary not up to date, please simulate again.</p>
    <p class="sim-again-text"  v-if="Object.keys(savedParameters.External).length === 0 && Object.keys(savedParameters.Treatment).length === 0">Simulate in order to see the current summary</p>
</div>
</template>
<script>
import ParameterHeader from './ParameterHeader.vue'

export default {
    name: "Summary",
    components: {
        ParameterHeader,
    },
    props: {
        simPar: Object,
        selectedSummary: String,
        Response: Object,
        savedParameters: Object,
        newSimRequired: Boolean,
    },
    data() {
        return {
            treatment: ['Metformin', 'GLP', 'LAI', 'FAI'],
            external: ['Meals','PA','Stress'],
        }
    },
    emits: [],
    methods: {
        convertMinutesToTime(minutes) {
            const hours = Math.floor(minutes / 60);
            const minutesRemainder = minutes % 60;
            const hoursString = hours.toString().padStart(2, '0');
            const minutesString = minutesRemainder.toString().padStart(2, '0');
            return `${hoursString}:${minutesString}`;
        },
    }
}
</script>
<style scoped>
.summary-header {
  border-top: 0px solid black !important;
}
.overlay {
  background-color: rgba(255, 0, 0, .05);
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: .6em;
  margin-bottom: 16px;
}
thead {
  background-color: #f0f0f0;
  font-weight: 100;
}
thead > th {
  font-size: 1.2em;
  font-weight: 400;
  color: grey;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}
th {
  text-align: center;
}
.sim-again-text {
  color: red;
  padding: 0px 10px;
  font-size: .8em;
  font-weight: 600;
  padding-bottom: 20px;
}
#summary-select {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 8px;
}
</style>