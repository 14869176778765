/**
 * Contains the list of states, submodels and tooltips
 * To add a new state, add it to the list below, and make sure to submodel exists in the list below.
 * To add tooltips, create a new line to the tooltips object, and make sure the key matches.
 */
var displayStates = {
    GBC : {l:false,r:false,name:"GBC", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    GBF : {l:false,r:false,name:"GBF", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    GH : {l:true,r:false,name:"GH", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    GG : {l:false,r:false,name:"GG", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    GL : {l:false,r:false,name:"GL", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    GK : {l:false,r:false,name:"GK", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    GPC : {l:false,r:false,name:"GPC", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    GPF : {l:false,r:false,name:"GPF", sub:"Glucose Sub-Model", unit:'[mmol/L]'},
    MIHGP : {l:false,r:false,name:"MIHGP", sub:"Glucose Sub-Model", unit:'[·]'},
    fr : {l:false,r:false,name:"fr", sub:"Glucose Sub-Model", unit:'[·]'},
    MIHGU : {l:false,r:false,name:"MIHGU", sub:"Glucose Sub-Model", unit:'[·]'},
    GHint : {l:false,r:false,name:"GHint*", sub:"Glucose Sub-Model", unit:'[·]'},
    IB : {l:false,r:false,name:"IB", sub:"Insulin Sub-Model", unit:'[mU/L]'},
    IH : {l:false,r:false,name:"IH", sub:"Insulin Sub-Model", unit:'[mU/L]'},
    IG : {l:false,r:false,name:"IG", sub:"Insulin Sub-Model", unit:'[mU/L]'},
    IL : {l:false,r:false,name:"IL", sub:"Insulin Sub-Model", unit:'[mU/L]'},
    IK : {l:false,r:false,name:"IK", sub:"Insulin Sub-Model", unit:'[mU/L]'},
    IPC : {l:false,r:false,name:"IPC", sub:"Insulin Sub-Model", unit:'[mU/L]'},
    IPF : {l:false,r:false,name:"IPF", sub:"Insulin Sub-Model", unit:'[mU/L]'},
    qss : {l:false,r:false,name:"qss", sub:"Glucose absorption model", unit:'[mg]'},
    qssGIH : {l:false,r:false,name:"qssGIH*", sub:"Glucose absorption model", unit:'[mg]'},
    qssGIM : {l:false,r:false,name:"qssGIM*", sub:"Glucose absorption model", unit:'[mg]'},
    qssGIL : {l:false,r:false,name:"qssGIL*", sub:"Glucose absorption model", unit:'[mg]'},
    qssGIvL : {l:false,r:false,name:"qssGIvL*", sub:"Glucose absorption model", unit:'[mg]'},
    qsl : {l:false,r:false,name:"qsl", sub:"Glucose absorption model", unit:'[mg]'},
    qslGIH : {l:false,r:false,name:"qslGIH*", sub:"Glucose absorption model", unit:'[mg]'},
    qslGIM : {l:false,r:false,name:"qslGIM*", sub:"Glucose absorption model", unit:'[mg]'},
    qslGIL : {l:false,r:false,name:"qslGIL*", sub:"Glucose absorption model", unit:'[mg]'},
    qslGIvL : {l:false,r:false,name:"qslGIvL*", sub:"Glucose absorption model", unit:'[mg]'},
    qint : {l:false,r:false,name:"qint", sub:"Glucose absorption model", unit:'[mg]'},
    qintGIH : {l:false,r:false,name:"qintGIH*", sub:"Glucose absorption model", unit:'[mg]'},
    qintGIM : {l:false,r:false,name:"qintGIM*", sub:"Glucose absorption model", unit:'[mg]'},
    qintGIL : {l:false,r:false,name:"qintGIL*", sub:"Glucose absorption model", unit:'[mg]'},
    qintGIvL : {l:false,r:false,name:"qintGIvL*", sub:"Glucose absorption model", unit:'[mg]'},
    De : {l:false,r:false,name:"De", sub:"Glucose absorption model", unit:'[mg]'},
    DNq : {l:false,r:false,name:"DNq", sub:"Glucose absorption model", unit:'[mg]'},
    MO1 : {l:false,r:false,name:"MO1", sub:"Metformin", unit:'[μg]'},
    MO2 : {l:false,r:false,name:"MO2", sub:"Metformin", unit:'[μg]'},
    MGl : {l:false,r:false,name:"MGL", sub:"Metformin", unit:'[μg]'},
    MGW : {l:false,r:false,name:"MGW", sub:"Metformin", unit:'[μg]'},
    ML : {l:false,r:false,name:"ML", sub:"Metformin", unit:'[μg]'},
    MP : {l:false,r:false,name:"MP", sub:"Metformin", unit:'[μg]'},
    AG1 : {l:false,r:false,name:"AG1", sub:"Vildagliptin", unit:'[nmol]'},
    AG2 : {l:false,r:false,name:"AG2", sub:"Vildagliptin", unit:'[nmol]'},
    Ac : {l:false,r:false,name:"Ac", sub:"Vildagliptin", unit:'[nmol]'},
    Ap : {l:false,r:false,name:"Ap", sub:"Vildagliptin", unit:'[nmol]'},
    DRc : {l:false,r:false,name:"DRc", sub:"Vildagliptin", unit:'[nmol]'},
    DRp : {l:false,r:false,name:"DRp", sub:"Vildagliptin", unit:'[nmol]'},
    E1 : {l:false,r:false,name:"E1", sub:"Physical Activity Model", unit:'[bpm]'},
    E2 : {l:false,r:false,name:"E2", sub:"Physical Activity Model", unit:'[·]'},
    Hfa : {l:false,r:false,name:"Hfa", sub:"Fast acting insulin injection model", unit:'[mU/dL]'},
    Dfa : {l:false,r:false,name:"Dfa", sub:"Fast acting insulin injection model", unit:'[mU/dL]'},
    Ifa : {l:false,r:false,name:"Ifa*", sub:"Fast acting insulin injection model", unit:'[mU/dL]'},
    Bla : {l:false,r:false,name:"Bla", sub:"Long acting insulin injection model", unit:'[mU/dL]'},
    Hla : {l:false,r:false,name:"Hla", sub:"Long acting insulin injection model", unit:'[mU/dL]'},
    Dla : {l:false,r:false,name:"Dla", sub:"Long acting insulin injection model", unit:'[mU/dL]'},
    Ila : {l:false,r:false,name:"Ila*", sub:"Long acting insulin injection model", unit:'[mU/dL]'},
    ml : {l:false,r:false,name:"ml", sub:"Pancreas Sub-Model", unit:'[μg]'},
    P : {l:false,r:false,name:"P", sub:"Pancreas Sub-Model", unit:'[·]'},
    R : {l:false,r:false,name:"R", sub:"Pancreas Sub-Model", unit:'[·]'},
    Gamma : {l:false,r:false,name:"Gamma", sub:"Glucagon Sub-Model", unit:'[·]'},
    psi : {l:false,r:false,name:"psi", sub:"Incretins Sub-Model", unit:'[pmol/dL]'},
    PSI : {l:false,r:false,name:"PSI", sub:"Incretins Sub-Model", unit:'[pmol/dL]'},
    psih2 : {l:false,r:false,name:"psih2*", sub:"Incretins Sub-Model", unit:'[mg]'},
    PSIh2 : {l:false,r:false,name:"PSIh2*", sub:"Incretins Sub-Model", unit:'[mg]'},
    psih24: {l:false,r:false,name:"psih24*", sub:"Incretins Sub-Model", unit:'[μg]'},
    PSIh24: {l:false,r:false,name:"PSIh24*", sub:"Incretins Sub-Model", unit:'[μg]'},
    // TE : {l:false,r:false,name:"", sub:"Physical Activity Model", unit:''},
    XGC : {l:false,r:false,name:"XGC", sub:"Total glucose consumption:*", unit:'[mmol]'},
    XGP : {l:false,r:false,name:"XGP", sub:"Total Glucose production and appearance:*", unit:'[mmol]'},
    XIC : {l:false,r:false,name:"XIC", sub:"Total insulin consumption:*", unit:'[mU]'},
    XIS : {l:false,r:false,name:"XIS", sub:"Total secreted inuslin:*", unit:'[mU]'},
    XIinj : {l:false,r:false,name:"XLinj", sub:"Total injected insulin:*", unit:'[mU]'},
    hout: {l:false,r:false,name:"Step size", sub:"ODE solver", unit:'[min]'},
    lai: {l:false,r:false,name:"Long Acting Insulin Dose", sub:"Closed-loop inputs", unit:'[U]'},
    fai: {l:false,r:false,name:"Fast Acting Insulin Dose", sub:"Closed-loop inputs", unit:'[U]'}
  }
var subModel= [
    "Glucose Sub-Model",
    "Insulin Sub-Model",
    "Glucose absorption model",
    "Metformin",
    "Vildagliptin",
    "Physical Activity Model",
    "Fast acting insulin injection model",
    "Long acting insulin injection model",
    "Pancreas Sub-Model",
    "Glucagon Sub-Model",
    "Incretins Sub-Model",
    // "Rates",
    "Total glucose consumption:*",
    "Total Glucose production and appearance:*",
    "Total insulin consumption:*",
    "Total secreted inuslin:*",
    "Total injected insulin:*",
    "ODE solver",
    "Closed-loop inputs"
]
var tooltips = {
    qss: "First Stomach compartment",
    qsl: "Second stomach compartment",
    qint: "Intestine compartment",
    De: "See equation 2",
    DNq: "See equation 2 ",
    MO1: "Oral metformin state 1 (see equation 9)",
    MO2: "Oral metformin state 2 (see equation 9)",
    MGl: "Metformin mass in the gastrointestina lumen compartment",
    MGW: "Metformin mass in the gastrointestina wall compartment",
    ML: "Metformin mass in the liver compartment",
    MP: "Metformin mass in the periphery compartment",
    E1: "See equation 10 ",
    E2: "See equation 10 ",
    Hfa: "Injected fast acting insulin (Hexameric)",
    Dfa: "Injected fast acting insulin (Diameric and Monomeric)",
    Bla: "Bound state for injected long acting insulin",
    Hla: "Injected long acting insulin (Hexameric)",
    Dla: "Injected long acting insulin (Diameric and Monomeric)",
    GBC: "Brain capillary space Glucose compartment",
    GBF: "Brain interstitial fluid Glucose compartment",
    GH: "Hepatic Glucose compartment",
    GG: "Guts Glucose compartment",
    GL: "Liver Glucose compartment",
    GK: "Kidney Glucose compartment",
    GPC: "Periphery capillary space Glucose compartment",
    GPF: "Periphery interstitial fluid Glucose Compartment",
    Gamma: "Glucagon compartment",
    IB: "Brain Insulin compartment",
    IH: "Hepatic Insulin compartment",
    IG: "Guts Insulin compartment",
    IL: "Liver Insulin compartment",
    IK: "Kidney Insulin compartment",
    IPC: "Periphery capillary space Insulin compartment",
    IPF: "Periphery interstitial space Insulin compartment",
    GC: "Total Glucose consumption",
    GP: "Total Glucose Production and appearance",
    IC: "Total Insulin consumption",
    IS: "Total Injected Insulin",
    ml: "See equation 13 ",
    P: "See equation 13 ",
    R: "See equation 13 ",
    AG1: "DPP-4 inhabitors in the guts compartment",
    AG2: "DPP-4 inhabitors in the absorption compartment",
    Ac: "DPP-4 inhabitors in the central compartment",
    Ap: "DPP-4 inhabitors in the peripheral compartment",
    DRc: "Deactivated DPP-4 in the central compartment",
    DRp: "Deactivated DPP-4 in the peripheral compartment",
    MIHGP: "See equation A.5c ",
    fr: "See equation A.5g ",
    MIHGU: "See equation A.5h ",
    Ila: "Injected long acting insulin in the periphery compartment",
    Ifa: "Injected fast acting insulin in the periphery compartment",
    Glucose: "Appendix A.1.",
    Glucoseabsorption: "Section 2.1",
    Metformin: "Section 2.3",
    Vildagliptin: "Appendix A.6.",
    Physical: "Section 2.4",
    Fast: "Section 2.2",
    Long: "Section 2.2",
    Pancreas: "Appendix A.5.",
    Insulin: "Appendix A.4.",
    Glucagon: "Appendix A.3.",
    Incretins: "Appendix A.2.",
    GHint: "Area under the curve of GH",
    psi: "First compartment for GLP-1",
    PSI: "Second compartment for GLP-1",
    psih2 : "First compartment for GLP-1 Daily [milligrams]",
    PSIh2 : "Second compartment for GLP-1 Daily [milligrams]",
    psih24: "First compartment for GLP-1 Daily [micrograms]",
    PSIh24: "Second compartment for GLP-1 Daily [micrograms]",
    hout: "internal step size of the ode solver",
    lai: "Computed long acting insulin dose, by user function",
    fai: "Computed fast acting insulin dose, by user function"
}
export default {displayStates, tooltips, subModel}